* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 16px;
}

h1 {
  font-size: 40px;
  letter-spacing: -1px;
}

a {
  text-decoration: none;
  color: rgb(0, 112, 230);
}

a:hover {
  text-decoration: underline;
}

small {
  font-size: 11px;
  color: #999;
}

.container {
  max-width: 750px;
  margin: 0 auto;
}

input::placeholder {
  color: #999;
}

.input {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 15px;
  font-family: monospace;
  line-height: 19px;
  letter-spacing: normal;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #eee;
  transition: 0.2s box-shadow ease;
}

.input:focus {
  outline: 2px solid transparent;
  border: 1px solid rgba(66, 153, 225, 0.45);
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
}

.input:invalid {
  border: 1px solid rgba(255, 20, 20, 0.36);
}

.input:invalid:focus {
  box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.08);
}

.parser-results {
  margin-top: 30px;
  padding: 24px;
  border: 1px solid #eee;
  border-radius: 5px;
  background: #eee;
  font-size: 14px;
}
